.main {
    width: 100%;
    height: auto;
    background-color: #fff;
}

a {
    text-decoration: none;
    cursor: pointer;
    color: inherit;
}

.section1 {
    overflow: hidden;
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: calc(100vh - 69px);
}

.section1-img {
    width: 100%;
    height: calc(100vh - 69px);
    background: url('/public/img/n__16.jpg') left/cover no-repeat;
    animation: moveImage 10s linear forwards;
}

@keyframes moveImage {
    0% {
        background-position: 0% 0;
    }

    100% {
        background-position: 100% 0;
    }
}

.section1-1 {
    width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.section1-1-1 {
    font-size: 40px;
    color: rgba(255, 255, 255, 1);
    font-family: NanumSquareOTF;
    font-style: normal;
    font-weight: lighter;
}

.section1-1-2 {
    font-family: NanumSquareOTF;
    font-style: normal;
    font-weight: bold;
    font-size: 110px;
    color: rgba(255, 255, 255, 1);
}

.section1-1-3 {
    font-family: NanumSquareOTF;
    font-style: normal;
    font-weight: lighter;
    font-size: 20px;
    color: rgba(255, 255, 255, 1);
}

.section1-2 {
    position: absolute;
    bottom: 30px;
    left: 50%;
    transform: translateX(-50%);
}

.section1-2 svg {
    font-size: 30px;
    font-weight: lighter;
}

.section1-2-1 {
    font-size: 14px;
}

.section2 {
    width: 100%;
    min-height: 1200px;
    position: relative;
}

.section2-box {
    position: absolute;
    width: 1000px;
    height: auto;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.section2-1 {
    margin-bottom: 40px;
}

.section2-1-1 {
    font-weight: bold;
    font-size: 43px;
    color: rgba(0, 0, 0, 1);
    height: 60px;
    margin: 0 auto;
    cursor: pointer;
}

.section2-1 svg {
    font-size: 37px;
}

.section2-2 {
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: calc(100% - 60px);
    padding: 20px 30px;
    border-bottom: 1px solid #ebebeb;
    border-radius: 5px;
    cursor: pointer;
}

.section2-2-box {
    width: 100%;
    display: flex;
    gap: 30px;
}

.section2-2:hover {
    background-color: rgba(243, 244, 245, .5);
    transform: scale(1.01, 1.01);
    transition: all .1s ease-in-out;
}

.section2-2-img {
    width: 20%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
}

.section2-2-img img {
    width: 100%;
    height: auto;
}

.section2-3 {
    width: 80%;
    display: flex;
    flex-direction: column;
    gap: 12px;
    text-align: left;
    border-radius: 5px;
    cursor: pointer;
}

.section2-3-1 {
    font-size: 16px;
}

.section2-3-2 {
    font-size: 25px;
    height: 31px;
    font-weight: bold;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.section2-3-3 {
    font-size: 17px;
    height: 42px;
    overflow: hidden;
    text-overflow: ellipsis;
    font-family: 'NanumSquareRound', serif;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.section2-3-4 {
    font-size: 14px;
    color: #a6a7a9;
}

.section-2-btn {
    margin: 40px auto 0;
    cursor: pointer;
    height: 52px;
    width: 60px;
    padding: 0 22px;
    font-size: 16px;
    font-weight: 700;
    line-height: 1.75;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f3f4f5;
}
.section-2-btn:hover {
    background-color: #dadada;
}
.section3 {
    width: 100%;
    height: 400px;
    position: relative;
    background: url('/public/img/n__8.jpg') center/cover no-repeat;
}

.section3 img {
    width: 100%;
}

.section3-1 {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: calc(100% - 40px);
    padding: 0 20px;
}

.section3-1-1 {
    font-size: 45px;
    opacity: 0.9;
    color: rgba(255, 255, 255, 1);
    font-weight: bold;
}

.section3-1-2 {
    font-size: 16px;
    color: rgba(255, 255, 255, 1);
    opacity: 0.7;
}

.section4 {
    height: 800px;
    position: relative;
    width: calc(100% - 40px);
    padding: 0 20px;
    max-width: 1500px;
    margin: 150px auto;
}


.section4-box {
    position: absolute;
    height: auto;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
}

.section4-1 {
    width: calc(100% - 200px);
    height: 350px;
    position: relative;
    margin: 0 100px;
}

.section4-1-1 {
    position: absolute;
    left: 0;
    top: 0;
    width: 57%;
    height: 200px;
    border-radius: 200px;
    background-color: #083468d4;
    z-index: 2;
    font-weight: bold;
    font-size: 30px;
    color: rgba(238, 244, 251, 1);
    display: flex;
    align-items: center;
    justify-content: center;
}

.section4-1-2 {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 57%;
    height: 200px;
    border-radius: 200px;
    background-color: #0150ad82;
    z-index: 1;
    font-weight: bold;
    font-size: 30px;
    color: rgba(4, 25, 82, 1);
    display: flex;
    align-items: center;
    justify-content: center;
}

.section4-2 {
    margin: 60px auto;
    position: relative;
    cursor: pointer;
    min-height: 180px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.section4-2-1 {
    font-weight: bold;
    font-size: 44px;
    color: rgba(0, 0, 0, 1);
    margin-bottom: 5px;
}

.section4-2-2 {
    font-size: 20px;
    color: rgba(0, 0, 0, 1);
}

.section4-3 {
    width: 100%;
    display: flex;
}

.section4-3-1 {
    padding: 10px 0 20px;
    width: 20%;
    font-size: 26px;
    color: rgba(203, 203, 203);
    font-weight: bold;
    transition: all .2s ease-in-out;
    display: flex;
    align-items: end;
    justify-content: center;
}

.section4-3-1.active {
    color: rgba(8, 52, 104);
}

.section4-3-2 {
    padding: 20px 0 10px;
    width: 20%;
    font-size: 26px;
    color: rgba(203, 203, 203);
    font-weight: bold;
    transition: all .2s ease-in-out;
    display: flex;
    align-items: flex-start;
    justify-content: center;
}

.section4-3-2.active {
    color: rgba(8, 52, 104);
}

.section4-4 {
    width: 100%;
    display: flex;
}

.section4-4-1 {
    width: 20%;
    height: 7px;
    background-color: rgba(203, 203, 203);
    cursor: pointer;
}

.section4-4-1.active {
    background-color: rgba(8, 52, 104);
}

.section5 {
    position: relative;
    width: calc(100% - 40px);
    padding: 0 20px;
    max-width: 1500px;
    height: auto;
    margin: 150px auto;
}

.section5-1 {
    width: 100%;
    height: 500px;
    position: relative;
}

.section5-1-1 {
    position: absolute;
    left: 0;
    width: 50%;
}

.section5-1-1-1 {
    font-weight: bold;
    font-size: 50px;
    color: rgba(0, 0, 0, 1);
    margin-bottom: 5px;
}

.section5-1-1-2 {
    font-size: 16px;
    color: rgba(0, 0, 0, 1);
    margin-bottom: 40px;
}

.section5-1-1-3 {
    width: 100%;
    border-radius: 20px;
}

.section5-1-2 {
    position: absolute;
    right: 0;
    width: 50%;
}

.section6 {
    width: 100%;
    height: 400px;
    position: relative;
    background: url('/public/img/n__11.jpg') center/cover no-repeat;
}

.section6 img {
    width: 100%;
}

.section6-1 {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 850px;
}

.section6-1-1 {
    font-size: 50px;
    color: rgba(255, 255, 255, 1);
    font-weight: bold;
    opacity: 0.9;
    margin-bottom: 10px;
}

.section6-1-2 {
    font-size: 18px;
    color: rgba(255, 255, 255, 1);
    opacity: 0.7;
}

.section7 {
    height: 500px;
    position: relative;
    width: calc(100% - 40px);
    padding: 0 20px;
    max-width: 1500px;
    margin: 150px auto;
}

.section7-1 {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
}

.section7-1-1 {
    font-weight: bold;
    font-size: 50px;
    color: rgba(0, 0, 0, 1);
    margin-bottom: 10px;
}

.section7-1-2 {
    font-size: 16px;
    color: rgba(0, 0, 0, 1);
    margin-bottom: 50px;
}

.section7-2 {
    width: calc(100% - 40px);
    padding: 0 20px;
    max-width: 1500px;
    height: 250px;
    cursor: pointer;
}

.section7-2-1 {
    position: relative;
    width: 170px !important;
    background-color: #d1d1d1;
    filter: drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.161));
}

.section7-2-1 img {
    width: 99%;
    height: 99%;
    border-radius: 10px;
}

.section7-2-2 {
    width: 100%;
    height: 50%;
    position: absolute;
    bottom: 0;
    background: linear-gradient(to top, #000000, #fff);
    opacity: .4;
}

.section7-2-2 span {
    color: #fff;
    font-size: 10px;
    font-weight: bold;
    position: absolute;
    bottom: 5px;
    left: 5px;
}

.section8 {
    width: 100%;
    height: 450px;
    background: url('/public/img/n__20.jpg') center/cover no-repeat;
}

@media (max-width:1200px) {
    .section1-1-1 {
        font-size: 30px;
    }

    .section1-1-2 {
        font-size: 90px;
    }

    .section1-1-3 {
        font-size: 17px;
    }

    .section2-box {
        width: calc(100% - 40px);
        padding: 0 20px;
    }

    .section4-3-1 {
        font-size: 22px;
    }

    .section4-3-2 {
        font-size: 22px;
    }

    .section4-1 {
        width: calc(100% - 100px);
        margin: 0 50px;
    }

    .section4-1-1 {
        font-size: 27px;
    }

    .section4-1-2 {
        font-size: 27px;
    }

    .section5-1 {
        height: 400px;
    }

    .section5-1-1 {
        width: 60%;
    }

    .section5-1-2 {
        width: 60%;
    }

    .section5-1-1-1 {
        font-size: 40px;
    }

    .section5-1-1-2 {
        font-size: 14px;
        margin-bottom: 25px;
    }

    .section5-1-1-3 {
        height: 250px;
    }
}

@media (max-width:1000px) {
    .section4-1 {
        width: calc(100% - 40px);
        margin: 0 20px;
    }

    .section4-1-1 {
        font-size: 22px;
    }

    .section4-1-2 {
        font-size: 22px;
    }

    .section4-2-1 {
        font-size: 38px;
    }

    .section4-2-2 {
        font-size: 17px;
    }

    .section4-3-1 {
        font-size: 19px;
    }

    .section4-3-2 {
        font-size: 19px;
    }

    .section8 {
        height: 350px;
    }

    .section-2-btn {
        width: 46px;
        font-size: 14px;
        height: 40px;
    }
}

@media (max-width:900px) {
    .section3 {
        height: 350px;
    }

    .section3-1-1 {
        font-size: 35px;
    }

    .section3-1-2 {
        font-size: 15px;
    }

    .section6 {
        height: 350px;
    }

    .section6-1-1 {
        font-size: 35px;
    }

    .section6-1-2 {
        font-size: 15px;
    }
}

@media (max-width:800px) {
    .section4-1-1 {
        width: 100%;
        border-radius: 150px;
        font-size: 20px;
    }

    .section4-1-2 {
        width: 100%;
        border-radius: 150px;
        font-size: 20px;
    }

    .section4-2 {
        margin: 30px 10px;
    }

    .section4-2-1 {
        font-size: 30px;
    }

    .section4-2-2 {
        font-size: 15px;
    }

    .section4-3-1 {
        font-size: 16px;
    }

    .section4-3-2 {
        font-size: 16px;
    }

    .section4-4-1 {
        height: 5px;
    }

    .section4-4-1.active {
        height: 5px;
    }

    .section5-1-1 {
        width: 100%;
    }

    .section5-1-2 {
        width: 100%;
    }

    .section7-1-1 {
        font-size: 40px;
    }

    .section7-1-2 {
        font-size: 15px;
    }

    .section7-2-1 {
        width: 150px !important;
        height: 220px !important;
        background-color: #d1d1d1;
        filter: drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.161));
    }
}

@media (max-width:750px) {
    .section2-3-1 {
        font-size: 14px;
    }

    .section2-3-2 {
        font-size: 20px;
        height: 27px;
    }

    .section2-3-3 {
        font-size: 15px;
        height: 35px;
    }

    .section2-3-4 {
        font-size: 12px;
    }

    .section3 {
        height: 300px;
    }

    .section3-1-1 {
        font-size: 25px;
    }

    .section3-1-2 {
        font-size: 13px;
    }

    .section6 {
        height: 300px;
    }

    .section6-1-1 {
        font-size: 25px;
    }

    .section6-1-2 {
        font-size: 13px;
    }

    .section1-img {
        width: 100%;
        height: calc(100vh - 69px);
        background: url('/public/img/n__16.jpg') left/cover no-repeat;
        animation: moveImageMobile 8s linear forwards;
    }

    @keyframes moveImageMobile {
        0% {
            background-position: 30% 0;
        }

        100% {
            background-position: 70% 0;
        }
    }
}

@media (max-width:550px) {
    .section1-1-1 {
        font-size: 20px;
    }

    .section1-1-2 {
        font-size: 60px;
    }

    .section1-1-3 {
        font-size: 16px;
    }

    .section2 {
        min-height: 1400px;
    }

    .section2-1-1 {
        font-size: 40px;
        height: 55px;
    }

    .section2-1 svg {
        font-size: 30px;
    }

    .section2-2-box {
        flex-direction: column;
    }

    .section2-2-img {
        width: 35%;
        margin: 0 auto;
    }

    .section2-3 {
        width: 100%;
    }

    .section3 {
        height: 250px;
    }

    .section3-1-1 {
        font-size: 20px;
    }

    .section3-1-2 {
        font-size: 12px;
    }

    .section6 {
        height: 250px;
    }

    .section6-1-1 {
        font-size: 20px;
    }

    .section6-1-2 {
        font-size: 12px;
    }

    .section5-1-1-1 {
        font-size: 30px;
    }

    .section5-1-1-2 {
        font-size: 13px;
        margin-bottom: 20px;
    }

    .section7-1-1 {
        font-size: 30px;
    }

    .section7-1-2 {
        font-size: 13px;
    }

    .section7-2-1 {
        width: 140px !important;
        height: 200px !important;
        background-color: #d1d1d1;
        filter: drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.161));
    }

    .section4-2-1 {
        font-size: 25px;
    }

    .section4-1-1 {
        border-radius: 100px;
    }

    .section4-1-2 {
        border-radius: 100px;
    }

    .section7 {
        margin: 50px auto;
    }

    .section8 {
        height: 300px;
    }

    .section4-3-1 {
        width: 50%;
    }

    .section4-3-2 {
        width: 50%;
    }

    .section4-3-1.none {
        width: 12%;
    }

    .section4-3-2.none {
        width: 35%;
    }
}