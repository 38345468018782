.about {
    width: 100%;
    height: auto;
    background-color: #fff;
}

.about-top {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}

.about-top-img {
    width: 100%;
    height: 40vh;
    background: url('/public/img/n_24.jpg') center/cover no-repeat;
}

.about-top img {
    height: 600px;
}

.about-top-1 {
    height: 450px;
    width: 100%;
    position: relative;
}

.about-top-1-1 {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 800px;
    width: calc(100% - 40px);
    padding: 0 20px;
}

.about-top-1-2 {
    font-weight: bold;
    font-size: 44px;
    color: rgba(52, 63, 82, 1);
    margin-bottom: 30px;
}

.about-top-1-3 {
    font-size: 20px;
    color: rgba(52, 63, 82, 1);
    margin-bottom: 30px;
}

.about-top-1-4 {
    font-size: 20px;
    color: rgba(52, 63, 82, 1);
}

.about-section1 {
    width: 100%;
    height: auto;
}

.about-section1-1 {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 50px;
    height: 64px;
}

.about-section1-1-2 {
    font-size: 20px;
    font-weight: 700;
    line-height: 1.6;
    white-space: nowrap;
    color: #d6d7da;
    cursor: pointer;
}

.about-section1-1-2.active {
    color: #232324;
}

.about-section1-2 {
    width: 100%;
    height: 1px;
    background-color: #d6d7da;
}

.about-section1-3 {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    height: 64px;
}

.about-section1-3-1 {
    height: 40px;
    margin: 0 2px;
    padding: 0 15px;
    font-size: 14px;
    line-height: 1.7142857143;
    color: #a6a7a9;
    background-color: #f3f4f5;
    border-radius: 20px;
    transition: color .3s, background-color .3s;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.about-section1-3-1.active {
    color: #fff;
    background-color: #37373a;
}

.about-section1-4 {
    margin: 150px auto;
    width: calc(100% - 40px);
    padding: 0 20px;
    max-width: 1500px;
    height: auto;
}

.about-section1-4-1 {
    color: #232324;
    font-weight: bold;
    font-size: 50px;
    margin-bottom: 30px;
}

.about-section1-4-2 {
    width: 100%;
    height: 500px;
    margin: 0 auto;
    background: url('/public/img/n__17.jpg') center/cover no-repeat;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-weight: bold;
    font-size: 110px;
    border-radius: 20px;
}

.about-section1-4-3 {
    margin-top: 50px;
    text-align: left;
}

.about-section1-4-3-1 {
    font-weight: bold;
    font-size: 30px;
    color: #232324;
    margin-bottom: 20px;
}

.about-section1-4-3-2 {
    font-size: 20px;
}

.about-section1-5 {
    width: 100%;
    height: 450px;
    background: url('/public/img/n__18.jpg') center/cover no-repeat;
}

.about-section1-6 {
    width: 100%;
    max-width: 1500px;
    height: auto;
    margin: 150px auto;
}

.about-section1-6-1 {
    color: #232324;
    font-weight: bold;
    font-size: 50px;
}

.about-section1-6-2 {
    width: 100%;
    height: 400px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 100px;
}

.about-section1-6-2-1 {
    width: 50%;
    height: 100%;
    background: url('/public/img/n__24.jpg') center/cover no-repeat;
    border-radius: 20px;
}

.about-section1-6-2-2 {
    width: 50%;
    height: 100%;
    position: relative;
}

.about-section1-6-2-3 {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80%;
}

.about-section1-6-2-4 {
    font-weight: bold;
    font-size: 40px;
    color: #242424;
    margin-bottom: 10px;
}

.about-section1-6-2-5 {
    font-size: 20px;
    color: #242424;
}

.about-section1-6-3-1 {
    width: 50%;
    height: 100%;
    background: url('/public/img/n__30.jpg') center/cover no-repeat;
    border-radius: 20px;
}

.about-section1-6-4-1 {
    width: 50%;
    height: 100%;
    background: url('/public/img/n__28.jpg') center/cover no-repeat;
    border-radius: 20px;
}

.about-section1-6-5-1 {
    width: 50%;
    height: 100%;
    background: url('/public/img/n__29.jpg') center/cover no-repeat;
    border-radius: 20px;
}

.about-section1-7 {
    width: 100%;
    height: 450px;
    background: url('/public/img/n__19.jpg') center/cover no-repeat;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 55px;
    font-weight: bold;
}

.about-section1-8 {
    width: calc(100% - 40px);
    padding: 0 20px;
    max-width: 800px;
    height: auto;
    margin: 150px auto;
}

.about-section1-8-1 {
    color: #232324;
    font-weight: bold;
    font-size: 50px;
    margin-bottom: 30px;
}

.about-section1-8-2 {
    font-size: 20px;
    color: rgba(0, 0, 0, 1);
    text-align: left;
}

.about-section2 {
    width: 100%;
    height: auto;
}

.about-section2-1 {
    width: calc(100% - 40px);
    padding: 0 20px;
    max-width: 1200px;
    height: auto;
    margin: 150px auto;
}

.about-section2-2 {
    margin: 100px 0;
    width: 100%;
    height: 300px;
    position: relative;
}

.about-section2-2-1 {
    width: calc(100% - 40px);
    margin: 0 20px;
    height: 100%;
    position: relative;
}

.about-section2-2-2 {
    width: 4px;
    height: 300px;
    background: linear-gradient(to bottom, #45464b, #fff);
    border-radius: 10px;
    position: absolute;
    top: 12px;
    left: 15px;
}

.about-section2-2-3 {
    width: 35px;
    height: 35px;
    background-color: #45464b;
    border-radius: 50%;
    position: absolute;
    top: 12px;
    left: 0px;
    z-index: 1;
}

.about-section2-2-4 {
    font-weight: bold;
    font-size: 40px;
    position: absolute;
    left: 70px;
}

.about-section2-3 {
    position: absolute;
    top: 100px;
    left: 50%;
    transform: translate(-50%);
    padding-left: 300px;
    width: calc(100% - 300px);
}

.about-section2-3-1 {
    font-size: 23px;
    text-align: left;
    margin-bottom: 30px;
    display: flex;
    justify-content: flex-start;
    gap: 50px;
}

.about-section3 {
    width: 100%;
    height: auto;
}

.about-section3-1 {
    margin: 150px 0 200px 0;
    width: calc(100% - 40px);
    padding: 0 20px;
    height: auto;
}

.about-section3-1-1 {
    width: 100%;
    max-width: 1200px;
    height: auto;
    margin: 150px auto;
    text-align: left;
}

.about-section3-1-2 {
    font-size: 24px;
    color: rgba(2, 16, 36, 1);
    letter-spacing: 0.1px;
    margin-bottom: 10px;
}

.about-section3-1-2 span {
    font-size: 16px;
    color: rgba(2, 16, 36, 1);
    letter-spacing: 0.1px;
    margin-left: 70px;
}

.about-sectiion3-1-3 {
    display: flex;
    align-items: center;
    justify-content: space-around;
    gap: 28px;
    width: 100%;
    flex-wrap: wrap;
}

.about-sectiion3-1-3 img {
    width: 18%;

}

.about-section2-3-1-1 {
    min-width: 50px;
}

@media (max-width:1200px) {
    .about-section2-2-2 {
        height: 250px;
        left: 13px;
    }

    .about-section2-2-3 {
        width: 30px;
        height: 30px;
    }

    .about-section2-2-4 {
        font-size: 30px;
        top: 5px;
    }

    .about-section2-3 {
        padding-left: 250px;
        width: calc(100% - 250px);
    }

    .about-section2-3-1 {
        font-size: 20px;
        margin-bottom: 27px;
        gap: 35px;
    }
    .about-sectiion3-1-3 {
        gap: 20px;
    }
}

@media (max-width:1000px) {
    .about-top-1-2 {
        font-size: 38px;
    }

    .about-top-1-3 {
        font-size: 17px;
    }

    .about-top-1-4 {
        font-size: 17px;
    }

    .about-section1-4-1 {
        font-size: 40px;
    }

    .about-section1-4-2 {
        height: 400px;
        font-size: 70px;
    }

    .about-section1-4-3-1 {
        font-size: 25px;
    }

    .about-section1-4-3-2 {
        font-size: 17px;
    }

    .about-section1-5 {
        height: 350px;
    }

    .about-section1-6-1 {
        font-size: 40px;
    }

    .about-section1-6-2 {
        height: 350px;
        margin-top: 70px;
    }

    .about-section1-6-2-4 {
        font-size: 35px;
    }

    .about-section1-6-2-5 {
        font-size: 17px;
    }

    .about-section1-8-1 {
        font-size: 40px;
    }

    .about-section1-8-2 {
        font-size: 17px;
    }

    .about-section2-3 {
        top: 75px;
        padding-left: 150px;
        width: calc(100% - 150px);
    }

    .about-section2-3-1 {
        font-size: 18px;
        gap: 30px;
    }
}

@media (max-width:900px) {
    .about-section1-7 {
        height: 350px;
        font-size: 35px;
    }
    .about-sectiion3-1-3 {
        gap: 15px;
    }
    .about-sectiion3-1-3 img {
        width: 17%;
    }
}

@media (max-width:800px) {
    .about-section2-3 {
        padding-left: 100px;
        width: calc(100% - 100px);
    }

    .about-section2-3-1 {
        font-size: 16px;
        gap: 15px;
    }
}

@media (max-width:750px) {
    .about-top-1-2 {
        font-size: 35px;
    }

    .about-top-1-3 {
        font-size: 15px;
    }

    .about-top-1-4 {
        font-size: 15px;
    }

    .about-section1-6-2 {
        width: 100%;
        height: 350px;
        margin-top: 30px;
        flex-direction: column-reverse;
    }

    .about-section1-6-2-2 {
        width: calc(100% - 40px);
        margin: 0 20px;
    }

    .about-section1-6-2-3 {
        width: 100%;
    }

    .about-section1-6-2-1 {
        width: calc(100% - 40px);
        margin: 0 20px;
    }

    .about-section1-6-3-1 {
        width: calc(100% - 40px);
        margin: 0 20px;
    }

    .about-section1-6-4-1 {
        width: calc(100% - 40px);
        margin: 0 20px;
    }

    .about-section1-6-5-1 {
        width: calc(100% - 40px);
        margin: 0 20px;
    }

    .about-section1-7 {
        height: 300px;
        font-size: 25px;
    }
}

@media (max-width: 700px) {
    .about-section2-2-1 {
        width: 100%;
        margin: 0;
        height: 100%;
        position: relative;
    }

    .about-section2-3 {
        padding-left: 50px;
        width: calc(100% - 50px);
    }

    .about-section2-3-1 {
        font-size: 15px;
        gap: 0px;
    }
}

@media (max-width:550px) {
    .about-top-1 {
        height: 400px;
    }
    .about-section1-1 {
        gap: 35px;
    }
    .about-top-1-2 {
        font-size: 30px;
    }

    .about-section1-1-2 {
        font-size: 18px;
    }

    .about-section1-4-2 {
        height: 350px;
        font-size: 50px;
    }

    .about-section1-4-3-1 {
        font-size: 23px;
    }

    .about-section1-4-3-2 {
        font-size: 16px;
    }

    .about-section1-5 {
        height: 300px;
    }

    .about-section1-6-2-4 {
        font-size: 30px;
    }

    .about-section1-6-2-5 {
        font-size: 15px;
    }

    .about-section1-6-2-4 span {
        font-size: 21px;
    }

    .about-section1-7 {
        height: 250px;
        font-size: 20px;
    }

    .about-section1-8-2 {
        font-size: 16px;
    }

    .about-section2-3 {
        padding-left: 40px;
        width: calc(100% - 40px);
    }
    .about-section3-1-2 span {
        margin-left: 50px;
    }
    .about-sectiion3-1-3 img {
        width:25%;
    }
}