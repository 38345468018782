.insights {
    width: 100%;
    height: auto;
    background-color: #fff;
}

.insights-top {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}

.insights-top-img {
    width: 100%;
    height: 40vh;
    background: url('/public/img/news.jpg') center/cover no-repeat;
}

.insights-section1 {
    width: calc(100% - 40px);
    padding: 0 20px;
    max-width: 800px;
    height: auto;
    margin: 200px auto;
}

.insights-section1-1 {
    font-weight: bold;
    font-size: 44px;
    color: rgba(52, 63, 82, 1);
    margin-bottom: 10px;
}

.insights-section1-2 {
    font-size: 20px;
    color: rgba(52, 63, 82, 1);
}

.insights-section2 {
    width: calc(100% - 20px);
    min-height: 350px;
    background: url('/public/img/n__23.jpg') center/cover no-repeat;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    padding: 20px 10px;
}

.insights-section2-1 {
    width: 25%;
    border-radius: 20px;
    height: 170px;
    background-color: rgba(243, 243, 243, 1);
    text-align: left;
    padding: 30px;
    display: flex;
    flex-direction: column;
    gap: 15px;
    cursor: pointer;
}
.insights-section2-1:hover {
    background-color: #e4e3e3;
    transform: scale(1.01, 1.01);
}
.insights-section3 {
    width: calc(100% - 40px);
    padding: 0 20px;
    max-width: 1200px;
    height: auto;
    margin: 150px auto;
    text-align: left;
}

.insights-section3-1 {
    font-weight: bold;
    font-size: 35px;
    color: rgba(0, 53, 119, 1);
    padding-left: 30px;
}

.insights-section-3-box {
    padding: 30px 100px 30px 30px;
    border-bottom: 1px solid #d1d1d1;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 30px;
    cursor: pointer;
    position: relative;
}

.insights-section-3-box:hover {
    background-color: rgba(243, 244, 245, .5);
    transform: scale(1.01, 1.01);
}

.insights-section-3-img {
    width: 20%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
}

.insights-section-3-img img {
    width: 80%;
    height: auto;
}

.insights-section3-2 {
    width: 80%;
    display: flex;
    gap: 12px;
    flex-direction: column;
    position: relative;
}

.insights-section3-2-1 {
    font-size: 16px;
    color: rgba(0, 0, 0, 1);
}

.insights-section3-2-2 {
    font-weight: bold;
    font-size: 25px;
    color: rgba(0, 0, 0, 1);
    letter-spacing: -0.1px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.insights-section3-2-3 {
    font-weight: lighter;
    font-size: 15px;
    color: rgba(0, 0, 0, 1);
    overflow: hidden;
    text-overflow: ellipsis;
    font-family: 'NanumSquareRound', serif;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.insights-section3-2-4 {
    font-weight: lighter;
    font-size: 12px;
    color: rgba(2, 21, 46, 1);

}

.insights-section-3-box svg {
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translate(-50%);
    font-size: 30px;
    color: rgba(0, 53, 119, 1);
}


.paging {
    text-align: right;
    display: inline-flex;
    align-items: center;
    justify-content: right;
    padding: 20px 24px;
    width: calc(100% - 48px);
}

.paging-pages>span {
    width: 24px;
    height: 24px;
    margin-left: 6px;
    min-width: auto;
    display: inline-block;
    text-align: center;
    vertical-align: middle;
    list-style: none;
    border: 1px solid #d5dae0;
    border-radius: 4px;
    outline: 0px;
    cursor: pointer;
    user-select: none;
    padding: 0px;
    transition: none 0s ease 0s;
    font-size: 12px;
    line-height: 22px;
    color: #121214;
}

.paging-pages>span:hover {
    background-color: rgba(0, 53, 119, 0.2);
}

/* .paging-btn {
    width: 24px;
    height: 24px;
    margin-left: 6px;
    min-width: auto;
    display: inline-block;
    text-align: center;
    vertical-align: middle;
    list-style: none;
    border: 1px solid #d5dae0;
    border-radius: 4px;
    outline: 0px;
    cursor: pointer;
    user-select: none;
    padding: 0px;
    transition: none 0s ease 0s;
    font-size: 12px;
    line-height: 22px;
    color: #121214;
} */


.paging-pages>span.active {
    background-color: rgba(0, 53, 119, 1);
    color: #fff;
    font-weight: bold;
}

@media (max-width:1000px) {
    .insights-section1-1 {
        font-size: 38px;
    }

    .insights-section1-2 {
        font-size: 17px;
    }
}

@media (max-width:750px) {
    .insights-section1-1 {
        font-size: 35px;
    }

    .insights-section1-2 {
        font-size: 16px;
    }

    .insights-section2 {
        height: auto;
        flex-direction: column;
    }

    .insights-section2-1 {
        width: calc(100% - 100px);
        margin: 0 20px;
        height: 150px;
    }

    .insights-section3-1 {
        padding-left: 10px;
    }

    .insights-section-3-box {
        padding: 10px 50px 10px 10px;
    }

    .insights-section-3-box svg {
        right: 10px;
        font-size: 25px;
    }
}

@media (max-width:550px) {
    .insights-section1 {
        margin: 100px auto;
    }

    .insights-section1-1 {
        font-size: 30px;
    }

    .insights-section1-2 {
        font-size: 15px;
    }

    .insights-section3-2-1 {
        font-size: 14px;
    }

    .insights-section3-2-2 {
        font-size: 21px;
    }

    .insights-section3-2-3 {
        font-size: 14px;
    }

    .insights-section3-2 {
        padding: 10px;
    }

    .insights-section-3-box svg {
        right: 10px;
        top: auto;
        bottom: 8px;
        font-size: 22px;
    }

    .insights-section3-1 {
        font-size: 32px;
    }

    .insights-section-3-box {
        flex-direction: column;
        padding: 30px 10px;
    }

    .insights-section-3-img {
        width: 35%;
        margin: 0 auto;
    }

    .insights-section3-2 {
        width: 100%;
    }
}