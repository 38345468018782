/* Header */
.preload-image {
    background-image: url('/public/img/patent/상표등록증_5건_KIDn_등_1.jpg');
    background-image: url('/public/img/patent/상표등록증_5건_KIDn_등_2.jpg');
    background-image: url('/public/img/patent/상표등록증_5건_KIDn_등_3.jpg');
    background-image: url('/public/img/patent/상표등록증_5건_KIDn_등_4.jpg');
    background-image: url('/public/img/patent/상표등록증_5건_KIDn_등_5.jpg');
    background-image: url('/public/img/patent/전자금융업_등록_완료_통보(주식회사_해피브릿지)_1.jpg');
    background-image: url('/public/img/patent/특허증_2건_IoT_1.jpg');
    background-image: url('/public/img/patent/특허증_2건_IoT_3.jpg');
    background-image: url('/public/img/patent/특허증_2건_화장품용기_1.jpg');
    background-image: url('/public/img/patent/특허증_2건_화장품용기_2.jpg');
    background-image: url('/public/img/patent/특허증_QR코드_또는_스마트_태그(온라인).jpg');
    background-image: url('/public/img/patent/특허증_QR코드_또는_스마트_태그_1.jpg');
    background-image: url('/public/img/patent/특허증_맞춤형_봉사료_1.jpg');
    background-image: url('/public/img/patent/특허증_모의_총기_시스템_1.jpg');
    background-image: url('/public/img/patent/특허증_사용환경동기화_1.jpg');
    background-image: url('/public/img/patent/특허증_스팸메일_차단방법_및_시스템(소멸)_1.jpg');
    background-image: url('/public/img/patent/특허증_액션피규어_1.jpg');
    background-image: url('/public/img/patent/특허증_유아용_바운서_1.jpg');
    background-image: url('/public/img/patent/특허증_자동제어기능_플러그_1.jpg');
    background-image: url('/public/img/patent/특허증_전기기기_관리장치_1.jpg');
    background-image: url('/public/img/patent/특허증_주소록_관리_시스템_1.jpg');
    visibility: hidden;
}
.header-up {
    width: 100%;
    height: 69px;
    background-color: #fff;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
    border-bottom: 1px solid #ebeef2;
    /* animation: header_up .3s ease-out; */
}

.header-down {
    position: relative;
    width: 100%;
    height: 69px;
    background-color: #fff;
}

@keyframes header_up {
    from {
        height: 50px;
    }

    to {
        height: 69px;
    }
}

.header-box {
    width: 100%;
    max-width: 1500px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 69px;
    position: relative;
}

.header-left {
    position: absolute;
    width: 150px;
    left: 0px;
    height: 69px;
    margin-left: 40px;
}

.header-left img {
    width: 100%;
    height: auto;
    position: absolute;
    top: 38%;
    transform: translate(-50%);
}

.header-mid {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    align-items: center;
    justify-content: center;
    height: 69px;
}

.header-mid-tit {
    font-size: 16px;
    padding: 0 20px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: #242424;
}

.header-mid-tit a {
    text-decoration: none;
    color: inherit;
}

.header-mid-tit:hover {
    color: #004ca9;
}

.header-mid-tit.active a {
    color: #004ca9;
}

.header-menu {
    position: absolute;
    overflow: visible;
    width: 100%;
    height: 50px;
    left: 0px;
    top: 69px;
    background-color: #fff;
    backdrop-filter: blur(30px);
    color: #242424;
    display: flex;
    justify-content: center;
    gap: 30px;
    z-index: 10;
    animation: toggle .4s ease-out;
}

@keyframes toggle {
    from {
        height: 0;
    }

    to {
        height: 50px;
    }
}

.header-menu-1 {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 30px;
    width: 100%;
}

.header-menu-1 div {
    margin-bottom: 3px;
    cursor: pointer;
    color: #d6d7da;
    font-size: 16px;
    line-height: 1.6;
    letter-spacing: -.2px;
}

.header-menu-1 div:hover {
    color: #232324;
    font-weight: 700;
}

.top-btn {
    position: fixed;
    bottom: 40px;
    right: 40px;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: black;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    z-index: 10;
}

.top-btn svg {
    color: #fff;
    font-size: 30px;
}

.header-ham {
    position: absolute;
    right: 0px;
    margin-right: 40px;
    font-size: 20px;
    cursor: pointer;
}

.header-nav {
    position: fixed;
    top: 0;
    right: 0;
    width: calc(100% - 50px);
    padding: 0 25px;
    height: 100%;
    background-color: #fff;
    z-index: 10;
    animation: menu .4s ease-out;
}

@keyframes menu {
    from {
        right: -300px;
        opacity: 0;
    }

    to {
        right: 0;
        opacity: 1;
    }
}

.header-nav-1 {
    height: 69px;
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.header-nav-1 a {
    display: flex;
    align-items: center;
}

.header-nav-1 img {
    width: 130px;
    position: absolute;
    left: 0;
}

.header-nav-1 svg {
    font-size: 20px;
    position: absolute;
    right: 0;
    cursor: pointer;
}

.header-nav-2 {
    height: 70px;
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
}

.header-nav-box {
    width: 100%;
    height: auto;
    border-bottom: 1px solid #ebeef2;
}

.header-nav-2-tit {
    font-size: 22px;
    font-weight: 700;
}

.header-nav-2 svg {
    font-size: 17px;
    position: absolute;
    right: 0;
}

.header-nav-2-txt {
    margin-left: 24px;
}

.header-nav-2-txt-1 {
    font-size: 16px;
    margin-bottom: 16px;
    color: #6c6d6f;
    text-align: left;
    cursor: pointer;
}










/* Footer */
.footer {
    width: 100%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    min-height: 350px;
    background-color: rgba(246, 251, 255, 1);
    box-shadow: 0 -9px 10px -11px rgba(0, 0, 0, 0.75);
}

.footer-box {
    width: 100%;
    min-height: 290px;
    max-width: 1200px;
    background-color: rgba(246, 251, 255, 1);
    position: relative;
    padding: 30px 60px;
}

.footer-box-1 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
}

.footer-1 {
    width: 450px;
    height: auto;
}

.footer-1 img {
    width: 150px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 15px;
}

.footer-1-1 {
    opacity: 0.5;
    font-size: 20px;
    color: rgba(2, 16, 36, 1);
    letter-spacing: -0.1px;
    text-align: left;
    margin-bottom: 20px;
}

.footer-1-2 {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.footer-1-2 img {
    width: 25px;
    height: 25px;
    margin-right: 70px;
}

.footer-2 {
    width: 550px;
    height: auto;
    display: flex;
    justify-content: end;
    gap: 60px;
    margin-bottom: 30px;
}

.footer-2-1-1 {
    font-weight: bold;
    font-size: 20px;
    color: rgba(0, 0, 0, 1);
    margin-bottom: 5px;
}

.footer-2-1-2 {
    font-weight: lighter;
    font-size: 15px;
    color: rgba(0, 0, 0, 1);
    margin-bottom: 3px;
}

.footer-3 {
    width: calc(100% - 120px);
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%);
    padding: 0 60px;
    margin-bottom: 20px;
}

.footer-3-1 {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.footer-3-1-1 {
    text-align: left;
    font-weight: bold;
    font-size: 18px;
    color: rgba(97, 129, 171, 1);
}

.footer-3-1-1 span {
    margin-left: 20px;
    font-weight: lighter;
}

.footer-3-1-2 {
    text-align: right;
    font-weight: lighter;
    font-size: 18px;
    color: rgba(97, 129, 171, 1);
}

.footer-3-1-2 span {
    margin-left: 20px;
    font-weight: lighter;
}

.footer-3-2 {
    width: 100%;
    height: 1px;
    background-color: #cfcfcf;
    margin: 10px 0;
}

.footer-3-3-1 {
    font-weight: lighter;
    font-size: 13px;
    color: rgba(97, 129, 171, 1);
    letter-spacing: 0.1px;
    text-align: left;
}

.footer-3-3-2 {
    display: flex;
    align-items: center;
    justify-content: end;
    gap: 30px;
}

.footer-3-3-2 svg {
    margin-right: 5px;
}

@media (max-width:1200px) {
    .footer-box {
        min-height: 450px;
        padding: 30px 20px 20px 20px;
    }

    .footer-box-1 {
        flex-direction: column;
    }

    .footer-1 {
        width: 100%;
    }

    .footer-2 {
        width: 100%;
    }
    .footer-3 {
        width: calc(100% - 40px);
        padding: 0 20px;
    }
}

@media (max-width:1000px) {
    .footer-box {
        min-height: 350px;
    }
    .footer-1-1 {
        font-size: 17px;
    }

    .footer-1-2 img {
        width: 22px;
        height: 22px;
    }

    .footer-2-1-1 {
        font-size: 17px;
    }

    .footer-2-1-2 {
        font-size: 14px;
    }

    .footer-3-1-1 {
        font-size: 14px;
    }

    .footer-3-1-2 {
        font-size: 14px;
    }

    .footer-3-3-1 {
        font-size: 11px;
    }

    .footer-3-3-2 {
        font-size: 14px;
    }

    .footer-3 {
        position: relative;
        width: 100%;
        padding: 0;
        margin-bottom: 0;
    }
}

@media (max-width:800px) {
    .footer-2 {
        gap: 40px;
    }

    .footer-3-1 {
        flex-direction: column;
    }

    .footer-3-1-1 {
        margin-bottom: 5px;
    }

    .footer-3-3-2 {
        font-size: 12px;
        margin-bottom: 5px;
    }
}

@media (max-width:550px) {
    .header-left {
        width: 130px;
        margin-left: 25px;
    }

    .header-ham {
        margin-right: 25px;
    }

    .footer {
        min-height: 400px;
    }

    .footer-box {
        padding: 30px 5px 20px 5px;
        min-height: 400px;
    }

    .footer-1 {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .footer-1 img {
        width: 130px;
    }

    .footer-1-1 {
        font-size: 15px;
        margin-bottom: 15px;
    }

    .footer-1-2 {
        gap: 50px;
    }

    .footer-1-2 img {
        width: 19px;
        height: 19px;
        margin-bottom: 30px;
        margin-right: 0;
    }

    .footer-2 {
        justify-content: center;
    }

    .footer-2-1 {
        width: 80px;
    }

    .footer-2-1-1 {
        font-size: 15px;
    }

    .footer-2-1-2 {
        font-size: 12px;
    }

    .footer-2 {
        gap: 20px;
    }

    .footer-3-1-1 {
        font-size: 11px;
    }

    .footer-3-1-2 {
        font-size: 11px;
    }

    .footer-3-3-2 {
        font-size: 11px;
        gap: 15px;
    }

    .footer-3-3-1 {
        font-size: 10px;
    }

    .top-btn {
        bottom: 20px;
        right: 20px;
    }
}