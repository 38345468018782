.contact {
    width: 100%;
    height: auto;
    background-color: #fff;
}

.contact-top {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}

.contact-top-img {
    width: 100%;
    height: 40vh;
    background: url('/public/img/n__21.jpg') center/cover no-repeat;
}

.contact-section1 {
    width: calc(100% - 40px);
    padding: 0 20px;
    max-width: 1500px;
    height: auto;
    margin: 200px auto;
}

.contact-section1-1 {
    font-weight: bold;
    font-size: 44px;
    color: rgba(52, 63, 82, 1);
    margin-bottom: 10px;
}

.contact-section1-2 {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 50px;
    margin-top: 50px;
}

.contact-section1-2-1 {
    width: 70%;
    height: 800px;
    border: 1px solid rgba(225,225,225,1);
    border-radius: 20px;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.161);
}

.contact-section1-2-2 {
    width: 30%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
}

.contact-section1-2-3{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    text-align: left;
    margin-bottom: 50px;
}
.conact-section1-2-3-1{
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.conact-section1-2-3-1 svg{
    font-size: 40px;
}
.conact-section1-2-3-2{
    margin-left: 20px;
}
.conact-section1-2-3-3{
    font-size: 30px;
    color: rgba(52,63,82,1);
    letter-spacing: 0.1px;
}
.conact-section1-2-3-4{
    font-size: 22px;
    color: rgba(36,36,36,1);
    letter-spacing: 0.1px;
}

.naver-map{
    width: 100%;
    height: 100%;
    border-radius: 20px;
}

@media (max-width:1200px) {
    .conact-section1-2-3-1 svg {
        font-size: 35px;
    }
    .conact-section1-2-3-3 {
        font-size: 25px;
    }
    .conact-section1-2-3-4 {
        font-size: 19px;
    }
}

@media (max-width:1000px) {
    .contact-section1-1 {
        font-size: 38px;
    }
    .conact-section1-2-3-1 svg {
        font-size: 30px;
    }
    .conact-section1-2-3-3 {
        font-size: 21px;
    }
    .conact-section1-2-3-4 {
        font-size: 17px;
    }
    .contact-section1-2 {
        gap: 40px;
    }
}

@media (max-width:750px) {
    .contact-section1 {
        margin: 100px auto;
    }
    .contact-section1-1 {
        font-size: 35px;
    }
    .conact-section1-2-3-1 svg {
        font-size: 26px;
    }
    .conact-section1-2-3-3 {
        font-size: 19px;
    }
    .conact-section1-2-3-4 {
        font-size: 15px;
    }
    .contact-section1-2 {
        gap: 25px;
        flex-direction: column;
    }
    .contact-section1-2-1 {
        width: 100%;
        height: 650px;
    }
    .contact-section1-2-2 {
        width: 100%;
    }
    .contact-section1-2-3 {
        margin-bottom: 25px;
    }
}

@media (max-width:550px) {
    .contact-section1-1 {
        font-size: 30px;
    }
    .contact-section1-2-1 {
        height: 400px;
    }
}