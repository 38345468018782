.business {
    width: 100%;
    height: auto;
    background-color: #fff;
}

.business-top {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}

.business-top-img {
    width: 100%;
    height: 40vh;
    background: url('/public/img/bg8.jpg') center/cover no-repeat;
}

.business-section1 {
    width: calc(100% - 40px);
    padding: 0 20px;
    max-width: 800px;
    height: auto;
    margin: 150px auto;
}

.business-section1-1 {
    font-weight: bold;
    font-size: 44px;
    color: rgba(52, 63, 82, 1);
    margin-bottom: 10px;
}

.business-section1-2 {
    font-size: 20px;
    color: rgba(52, 63, 82, 1);
}

.business-section2 {
    width: 100%;
    height: 500px;
    background: url('/public/img/n__20.jpg') center/cover no-repeat;
}

.business-section3 {
    margin: 150px 0;
    width: 100%;
    height: auto;
}

.business-section4 {
    width: 100%;
    height: 500px;
    background: url('/public/img/웹메일.jpg') center/cover no-repeat;
}

.business-section5 {
    width: 100%;
    height: 500px;
    background: url('/public/img/법인공용.jpg') center/cover no-repeat;
}

.business-section3-1 {
    margin: 150px auto;
    width: calc(100% - 40px);
    padding: 0 20px;
    max-width: 1500px;
    height: auto;
    margin: 150px auto;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 60px;
}

.business-section3-2 {
    margin: 150px auto;
    width: calc(100% - 40px);
    padding: 0 20px;
    max-width: 1500px;
    height: auto;
    margin: 150px auto;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 60px;
}

.business-section3-1-1 {
    width: 70%;
    text-align: left;
}

.business-section3-1-1-1 {
    font-size: 24px;
    color: rgba(52, 63, 82, 1);
    letter-spacing: 0.1px;
}

.business-section3-1-1-2 {
    font-weight: bold;
    font-size: 44px;
    color: rgba(0, 0, 0, 1);
    margin-bottom: 10px;
}

.business-section3-1-1-3 {
    font-size: 20px;
    color: rgba(0, 0, 0, 1);
    letter-spacing: 0.1px;
    opacity: 0.7;
}

.business-section3-1-2 {
    width: 30%;
}

.business-section3-1-2 img {
    width: 100%;
    border-radius: 20px;
}

@media (max-width:1000px) {
    .business-section1-1 {
        font-size: 38px;
    }

    .business-section1-2 {
        font-size: 17px;
    }

    .business-section3-1 {
        flex-direction: column;
    }

    .business-section3-2 {
        flex-direction: column-reverse;
    }

    .business-section3-1-1 {
        width: 100%;
    }

    .business-section3-1-1-1 {
        font-size: 21px;
    }

    .business-section3-1-1-2 {
        font-size: 38px;
    }

    .business-section3-1-1-3 {
        font-size: 17px;
    }
}

@media (max-width:750px) {
    .business-section1-1 {
        font-size: 35px;
    }

    .business-section1-2 {
        font-size: 15px;
    }
}

@media (max-width: 700px) {
    .about-section1-1-2 {
        font-size: 17px;
    }

    .about-section1-1 {
        gap: 30px;
        overflow-x: auto;
        justify-content: flex-start;
        padding: 0 20px;
    }

    .business-section3-1-2 {
        width: 40%;
    }
}

@media (max-width:550px) {
    .business-section1 {
        margin: 100px auto;
    }

    .business-section1-1 {
        font-size: 30px;
    }

    /* .about-section1-1-2 {
        font-size: 14px;
    }
    .about-section1-1 {
        gap:12px;
    } */
    .business-section3-1-1-1 {
        font-size: 18px;
    }

    .business-section3-1-1-2 {
        font-size: 29px;
    }

    .business-section3-1-1-3 {
        font-size: 16px;
    }

    .business-section3-1-2 {
        width: 50%;
    }
}